import { ref } from 'vue';
import { projectFunctions } from '../firebase/config';

const error = ref(null);
const isPending = ref(false);

const managerApproval = async (signUpCode) => {
    error.value = null;
    isPending.value = true;
    try {
        console.log(`SignUp Code: ${signUpCode}`);
        const approval = projectFunctions.httpsCallable("managerApproval");
        const res = await approval({ signUpCode });
        if (res.data.error) {
            error.value = res.data.error;
        }
        isPending.value = false;

    } catch (err) {
        error.value = `Approval Error: ${err.message}`;
        isPending.value = false;
    }
};

const updateURL = async (buildingId, url) => {
    error.value = null;
    isPending.value = true;
    try {
        const updateFunction = projectFunctions.httpsCallable("updateURL");
        const res = await updateFunction({
            buildingId: buildingId, url: url
        });
        if (res.data.error) {
            error.value = res.data.error;
        }
        isPending.value = false;
    } catch (err) {
        error.value = `Update URL Error: ${err.message}`;
        isPending.value = false;
    }
};


const useFunctions = () => {
    return { error, managerApproval, updateURL, isPending };
};

export default useFunctions;