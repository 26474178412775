import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/auth/Login.vue';
import SignUp from '../views/auth/SignUp.vue';
import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";

// route guard 
const requireAuth = (to, from, next) => {
  const { user, manager } = getUser();
  const loginPath = window.location.pathname;
  console.log("Require Auth?");
  if (!user.value) {
    console.log("No User!");
    next({ name: 'Login', query: { from: loginPath } });
  } else {
    console.log("We have a user!");
    if (!manager.value) {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-manager user");
        next({ name: 'Login', query: { from: loginPath } });
      });
    } else {
      next();
    }
  }
};

const requireNoAuth = async (to, from, next) => {
  const { user, manager } = getUser();
  console.log(`User: ${user.value}`);
  if (user.value) {
    if (manager.value) {
      next({ name: 'Home' });
    } else {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-manager user");
        next({ name: 'Login' });
      });
    }
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/buildings',
    name: 'Buildings',
    component: () => import('../views/buildings/Buildings.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: () => import('../views/tenants/Tenants.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/buildings/create',
    name: 'CreateBuilding',
    component: () => import('../views/buildings/building/CreateBuilding.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/building/:buildingId/details/',
    name: 'BuildingDetails',
    component: () => import('../views/buildings/building/BuildingDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/building/:buildingId/spaces/',
    name: 'Spaces',
    component: () => import('../views/buildings/spaces/Spaces.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/building/:buildingId/spaces/create',
    name: 'CreateSpace',
    component: () => import('../views/buildings/spaces/CreateSpace.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/offering/:buildingId/spaces/edit/:spaceId',
    name: 'EditSpace',
    component: () => import('../views/buildings/spaces/EditSpace.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/building/:buildingId/tenants/',
    name: 'BuildingTenants',
    component: () => import('../views/buildings/tenants/Tenants.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/building/:buildingId/tenants/create',
    name: 'CreateTenant',
    component: () => import('../views/buildings/tenants/CreateTenant.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/offering/:buildingId/tenants/edit/:tenantId',
    name: 'EditTenant',
    component: () => import('../views/buildings/tenants/EditTenant.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/building/:buildingId/rentroll/',
    name: 'RentRoll',
    component: () => import('../views/buildings/rentroll/RentRoll.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/building/:buildingId/rentroll/create',
    name: 'CreateLease',
    component: () => import('../views/buildings/rentroll/CreateLease.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/building/:buildingId/rentroll/edit/:leaseId',
    name: 'EditLease',
    component: () => import('../views/buildings/rentroll/EditLease.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: SignUp,
    beforeEnter: requireNoAuth,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
