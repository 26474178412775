<template>
  <header>
    <nav class="navbar fixed-top navbar-expand-md navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand" :href="homePage"
          ><img id="navbar-logo" src="@/assets/img/woob_logo.png" class="small"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          v-if="user && manager"
          class="collapse navbar-collapse"
          id="navbarCollapse"
        >
          <ul class="navbar-nav me-auto mb-2 mb-md-0 d-flex text-center">
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Buildings')"
                >Buildings</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Tenants')"
                >Tenants</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Account')"
                >Account</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Contact')"
                >Contact</span
              >
            </li>
          </ul>
          <div class="text-center">
            <span class="btn btn-link" @click="handleClick">
              Sign Out
            </span>
          </div>
        </div>
        <div v-else>
          <a class="nav-link" :href="tenantURL" style="margin-bottom: 5px"
            >Tenant Sign In</a
          >
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { onUnmounted, onMounted, ref } from "vue";
import useLogout from "@/composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";

export default {
  setup() {
    const { logout, error } = useLogout();
    const tenantURL = ref(process.env.VUE_APP_TENANT_URL);
    const homePage = ref(process.env.VUE_APP_HOST_NAME);
    const router = useRouter();
    const isScrolled = ref(false);
    const { user, manager } = getUser();

    const handleClick = async () => {
      await logout();
      if (!error.value) {
        router.push({ name: "Login" });
      } else {
        console.log("Error logging out: ", error.value);
      }
    };

    const handleScroll = () => {
      isScrolled.value =
        document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const closeMenuRoute = routeName => {
      router.push({ name: routeName });
    };

    return {
      handleScroll,
      isScrolled,
      handleClick,
      user,
      manager,
      tenantURL,
      homePage,
      closeMenuRoute
    };
  }
};
</script>
<style>
/* HEADER / TOP MENU
------------------------------------------------- */

#navbar-logo {
  height: 80px;
  transition-property: height;
  transition-duration: 0.2s;
}

#navbar-logo.small {
  height: 40px;
}

li.nav-item {
  text-align: center;
  margin-right: 5px;
}

.navbar-expand-md .navbar-nav .nav-link {
  margin-top: 6px;
  color: #404042;
  font-weight: 600;
  text-transform: uppercase;
}
</style>