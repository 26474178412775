import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { projectAuth } from './firebase/config';

//Bootstrap
import 'bootstrap/scss/bootstrap.scss';
import './assets/css/bootstrap-icons.css';
import { Modal } from "bootstrap";

//global styles
import './assets/css/main.css';

let app;

projectAuth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(router, Modal).mount('#app');
    }
});
