import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

// refs
const user = ref(projectAuth.currentUser);
const manager = ref(false);

// auth changes
projectAuth.onAuthStateChanged(_user => {
  console.log('User state change. Current user is:', _user);
  user.value = _user;
  if (user.value) {
    console.log("Get TokenId");
    user.value.getIdTokenResult().then((getIdTokenResult) => {
      if (getIdTokenResult.claims.manager) {
        console.log("manager is true");
        manager.value = true;
      } else {
        console.log("manager is false");
        manager.value = false;
      }
    });
  }
});

// set role
const setManager = (value) => {
  manager.value = value;
};

const getUser = () => {
  return { user, manager, setManager };
};

export default getUser;